html {
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

::selection {
  background-color: #ed125f;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

p,
strong,
button {
  font-family: "Montserrat", sans-serif;
}

strong {
  font-weight: bold;
}
